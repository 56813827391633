<template>
  <div class="rank">
    <v-touch
      @swiperight = "swipeRight"
      :swipe-options="{direction: 'horizontal'}" class="all">
    <div class="rank-title">
      <span :class="{active:newVideo}" @click="putnew">新片榜</span><span :class="{active:!newVideo}" @click="putUser">博主榜</span>
    </div>
    <transition name="slide-r">
    <div class="list" v-show="newVideo">
      <div class="list-item" v-if="newList.length>0">
        <div class="title flex-between">
          <div class="left"><span class="hot"></span>最新上传</div>
          <router-link tag="div" :to="{path:'/rankMoreVideo',query:{name:'最新上传'}}" class="right">查看更多<span class="go-more"></span></router-link>
        </div>
        <div class="video-list flex-between">
          <videocover :item="item" v-for="item in newList" :key="item.id"/>
        </div>
      </div>
      <div class="list-item" v-if="weekList.length>0">
        <div class="title flex-between">
          <div class="left"><span class="hot"></span>周热度榜</div>
          <router-link tag="div" :to="{path:'/rankMoreVideo',query:{name:'周热度榜'}}" class="right">查看更多<span class="go-more"></span></router-link>
        </div>
        <div class="video-list flex-between">
          <videocover :item="item" v-for="item in weekList" :key="item.id"/>
        </div>
      </div>
      <div class="list-item" v-if="monthList.length>0">
        <div class="title flex-between">
          <div class="left"><span class="hot"></span>月热度榜</div>
          <router-link tag="div" :to="{path:'/rankMoreVideo',query:{name:'月热度榜'}}" class="right">查看更多<span class="go-more"></span></router-link>
        </div>
        <div class="video-list flex-between">
          <videocover :item="item" v-for="item in monthList" :key="item.id"/>
        </div>
      </div>
      <van-loading type="spinner" color="#f21313" v-show="newList.length==0&&weekList.length==0&&monthList.length==0&&!noData"/>
    </div>
    </transition>
    <transition name="slide-l">
    <div class="list" v-show="!newVideo">
      <pullrefresh :disabled="true" @onLoad="onLoad" ref="pull" v-show="userList.length>0">
        <div v-for="(item,index) in userList" :key="item.blogger.id">
          <div class="list-item">
            <div class="title flex-between">
            <router-link tag="div" :to="{path:'/bloggerDetails',query:{ id:item.blogger.id}}" class="left"><ImgDecypt class="video-ad" :round="true" :src="item.blogger.avatarUrl"/>{{item.blogger.nickName}}</router-link>
            <router-link tag="div" :to="{path:'/userMoreVideo',query:{id:item.blogger.id}}" class="right">查看更多<span class="go-more"></span></router-link>
          </div>
            <div class="video-list flex-between">
              <videocover :item="items" v-for="items in item.mediaInfos" :key="items.id"/>
            </div>
          </div>
          <AdvSwiper
            :advList="listAd"
            v-if="(index + 1) % 3 == 0 && listAd.length > 0"
            class="l_adv"
          />
        </div>
      </pullrefresh>
      <van-loading type="spinner" color="#f21313" v-show="userList&&userList.length==0 && !noData"/>
      <nodata v-show="noData"/>
    </div>
    </transition>
    </v-touch>
  </div>
</template>
<script>
import videocover from '../widget/videoList.vue'
import AdvSwiper from "@/components/AdvSwiper/index.vue";
import {rankList,iconList,userRank} from '@/api/app.js';
import pullrefresh from "@/components/PullRefresh/index.vue";
import { getAdItem, AdType } from "@/utils/getAdv.js";
export default {
  components:{
    videocover,
    pullrefresh,
    AdvSwiper
  },
  data(){
    return{
      newVideo:true,
      newList:[],//最新上传
      weekList:[],//周排行榜
      monthList:[],//月排行榜
      userList:[],//博主排行榜
      pageNum:1,//博主排行榜的页码
      noData:false,//暂无数据
      listAd:[]
    }
  },
  created(){
    this.listAd = getAdItem(AdType.columnAd);
    this.getNewList();//最新上传
    this.getList('week');//周排行榜
    this.getList('month');//月排行榜
  },
  methods:{
    onLoad(){
      this.pageNum ++;
      this.userRank();
    },
    async userRank(){//博主排行榜
      let req = {
        isVip:true,
        mediaSort:'watch',//可选值: "like"最多点赞 "watch":最多观看
        rankingType:'hot',//可选值: "hot"人气博主 "new":新人博主,"income"收益榜
        pageNum:this.pageNum,
        pageSize:10,
      }
      let ret = await this.$Api(userRank,req);
      if(!this.$refs.pull) return;
      this.$refs.pull.loading = false;
      if(ret.code == 200){
        let list = ret.data.rankingBloggers?ret.data.rankingBloggers:[]
        this.userList = this.userList.concat(list);
        if(list.length < 10){
          this.$refs.pull.finished = true;
        }else{
          this.$refs.pull.finished = false;
        }
        if(this.pageNum == 1 && list.length == 0){
          this.noData = true;
        }
      }
    },
    async getNewList(){//最新上传
      let req = {
        isVip:true,
        pageNum:1,
        pageSize:6,
        type:'new'
      }
      let ret = await this.$Api(iconList,req);
      if(ret.code == 200){
        let list = ret.data.mediaInfos?ret.data.mediaInfos:[];
        this.newList = list;
      }
    },
    async getList(type){//获取排行榜接口数据 
      let req = {
        pageNum:1,
        pageSize:6,
        period:type
      }
      let ret = await this.$Api(rankList,req);
      if(ret.code == 200){
        let list = ret.data.mediaInfos ? ret.data.mediaInfos : [];
        if(type == 'week'){
          this.weekList = list;
        }else{
          this.monthList = list;
        }
      }
    },
    putnew(){
      this.newVideo = true;
      if(this.newList.length == 0 && this.weekList.length == 0 && this.monthList == 0){
        this.noData = false;
        this.getNewList();//最新上传
        this.getList('week');//周排行榜
        this.getList('month');//月排行榜
      }
    },
    putUser(){
      this.newVideo = false;
      if(this.userList.length == 0){
        this.noData = false;
        this.pageNum = 1;
        this.userRank();
      }
    },
    swipeRight(){
      // this.$router.push('/home/tag');
    }
  }
}
</script>
<style lang="scss" scoped>
.rank{
  padding:12px 6px 2px;
  box-sizing: border-box;
}
.all{
  position: relative;
}
.rank-title{
  padding:0 10px;
}
.rank-title>span{
  font-size:18px;
  margin-right:30px;
  color:$greyishBrownTwo;
}
.rank-title>span.active{
  color:$vermillion;
  font-weight:bold;
}
.list{
  position: absolute;
  padding-bottom:30px;
  width: 100%;
}
.list-item{
  padding:0 10px 20px;
  margin-top:20px;
  border-radius:15px;
  box-shadow: 0px 4px 8px rgba($color: #000000, $alpha: 0.2);
  overflow: hidden;
}
.l_adv{
  height: 100px;
  overflow: hidden;
  padding-top: 10px;
  /deep/ .swiper-container,/deep/ .swiperSlide{
    height: 100%;
  }
}
.title{
  align-items: center;
  margin:10px 0 5px;
}
.left{
  font-size:18px;
}
.right{
  font-size:14px;
  color:$greyishBrownTwo;
}
.hot{
  display: inline-block;
  width:20px;
  height:20px;
  background:url('../../assets/png/hot_icon.png') center center no-repeat;
  background-size:100%;
  margin-right:4px;
  vertical-align: middle;
}
.go-more{
  display: inline-block;
  width:8.5px;
  height:10.6px;
  margin-left:3px;
  background:url('../../assets/png/go_more.png') center center no-repeat;
  background-size:100%;
}
.video-list{
  flex-wrap: wrap;
}
.video-ad{
  display: inline-block;
  width:30px;
  height:30px;
  border-radius: 50%;
  overflow: hidden;
  border:1px solid #F21313;
  vertical-align: middle;
  margin-right: 7px;
}
.list /deep/ .van-pull-refresh{
  overflow: visible;
}
.list /deep/ .van-pull-refresh__head{
  display: none;
}
</style>
